import Frame from "../main/Frame";
import {Loading} from "../../common/Loading";
import icon_noti from "../../assets/svg/icon_noti.svg";
import {useEffect, useState} from "react";
import * as api from "../../api/api"
import * as hanaApi from "../../api/hanaApi";
import numeral from 'numeral'
import {connect, useSelector} from "react-redux";
import Alert from "../../common/Alert";

const CardTransfer = (props) => {
    const {user} = useSelector((props) => props.account);

    const [loading, setLoading] = useState(false)
    const [isAlert, setIsAlert] = useState(false)
    const [balance, setBalance] = useState([])
    const [cardInfo, setCardInfo] = useState({
        transferDiv: "C", targetNo: "", accountName: "", amount: "", accountOwner: "", bankName: "", bankCode: "000"
    })
    const [bankList, setBankList] = useState([])

    useEffect(() => {
        search()
        getBankCode()
    }, [])

    const search = () => {
        const body = {
            card_num: user.cardNo,
        }

        hanaApi.HCGetUserInfoCashSum(user.company, body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setBalance(data)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    const getBankCode = () => {
        api.getBankCode().then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setBankList(data)
                // 가져온 은행코드의 첫번째 값을 기본 설정
                setCardInfo((state) => ({
                    ...state,
                    bankCode: data[0].bankCode
                }))
            }
        })
            .catch(ex => window.alert(ex.message))
    }

    const request = () => {
        if (cardInfo.transferDiv === "C") {
            cardTransfer()
        }
        else if (cardInfo.transferDiv === "B") {
            withdraw()
        }
    }

    const withdraw = () => {
        if (window.confirm(cardInfo.targetNo + "로의 이체를 요청하시겠어요?")) {
            const body = {
                card_num: user.cardNo,
                day_limit: String(cardInfo.amount * -1),
                mon_limit: String(cardInfo.amount * -1),
                one_dollar_limit: String(cardInfo.amount * -1),
                mon_dollar_limit: String(cardInfo.amount * -1),
            }
            hanaApi.HCChangeLimit(user.company, body).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    accountTransfer()
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
        }
    }

    const accountTransfer = () => {
        const body = {
            cmpId: 1,
            cardNo: user.cardNo,
            wdDiv: cardInfo.transferDiv,
            requestId: user.id,
            requestNm: "",
            wdAccount: cardInfo.targetNo,
            wdBankCode: cardInfo.bankCode,
            wdBank: cardInfo.bankName,
            wdAccountOwner: cardInfo.accountOwner,
            amount: cardInfo.amount,
            isApproval: 0
        }

        api.putWithdraw(body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setIsAlert(true)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })

    }

    const cardTransfer = () => {
        if (window.confirm(cardInfo.targetNo + "로의 이체를 요청하시겠어요?")) {
            // 1. 내 balance 에서 차감
            const body = {
                card_num: user.cardNo,
                day_limit: String(cardInfo.amount * -1),
                mon_limit: String(cardInfo.amount * -1),
                one_dollar_limit: String(cardInfo.amount * -1),
                mon_dollar_limit: String(cardInfo.amount * -1),
            }
            deducted(body)
        }
    }

    const deducted = (body) => {
        hanaApi.HCChangeLimit(body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                // 2. 송금한다
                remit()
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    const remit = () => {
        const body = {
            card_num: cardInfo.targetNo,
            day_limit: String(cardInfo.amount),
            mon_limit: String(cardInfo.amount),
            one_dollar_limit: String(cardInfo.amount),
            mon_dollar_limit: String(cardInfo.amount),
        }

        hanaApi.HCChangeLimit(body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                window.alert("처리가 완료되었습니다.")
                search()
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    return (
        <Frame>
            {loading ? <Loading /> : null}
            <div className={"flex flex-col px-4 gap-2"}>
                <div className={"flex flex-row items-center justify-between"}>
                    <div className={"flex flex-row gap-2 items-center"}>
                        <img src={icon_noti} alt={""}/>
                        <span className={"text-[#454545]"}>법인체크카드 이체</span>
                    </div>
                    <span className={"text-[#454545] font-bold"}>{">"}</span>
                </div>
                <div className={"h-[30px]"}/>
                <div className={"flex flex-col bg-[#505050] rounded-lg shadow-md px-4 py-2 gap-2"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"text-center text-white text-sm font-bold"}>{"이체가능\n캐시포인트"}</span>
                        <span className={"text-white text-lg font-bold"}>{balance.length > 0 ? numeral(balance[0].cash_amount).format("0,0") : 0}</span>
                    </div>
                </div>
                <div className={"flex flex-col bg-[#878787] rounded-lg shadow-md p-5 gap-4"}>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-white font-bold"}>이체방식</span>
                        <select className={"bg-transparent text-white text-xl font-bold"}
                                onChange={e => {
                                setCardInfo((state) => ({
                                    ...state,
                                    transferDiv: e.target.value
                                }))
                        }}>
                            <option value={"C"}>카드번호</option>
                            <option value={"B"}>외부통장</option>
                        </select>
                    </div>
                    {cardInfo.transferDiv === 'C' &&
                    <div className={"flex flex-col gap-4"}>
                        <div className={"flex flex-col gap-1"}>
                            <span className={"text-white font-bold"}>카드번호</span>
                            <input className={"bg-transparent text-white font-bold text-xl"} placeholder={""}
                                   onChange={e => {
                                       setCardInfo((state) => ({
                                           ...state, targetNo: e.target.value
                                       }))
                                   }}
                                   value={cardInfo.targetNo}/>
                        </div>
                    </div>
                    }
                    {cardInfo.transferDiv === 'B' &&
                    <div className={"flex flex-col gap-4"}>
                        <div className={"flex flex-col gap-1"}>
                            <span className={"text-white font-bold"}>계좌번호</span>
                            <input className={"bg-transparent text-white font-bold text-xl"} placeholder={""}
                                   onChange={e => {
                                       setCardInfo((state) => ({
                                           ...state, targetNo: e.target.value
                                       }))
                                   }}
                                   value={cardInfo.targetNo}/>
                        </div>
                        <div className={"flex flex-col gap-1"}>
                            <span className={"text-white font-bold"}>입금은행</span>
                            <select className={"bg-transparent text-white text-xl font-bold"}
                                    onChange={e => {
                                        console.log("### code :: ", e.target.value)
                                        setCardInfo((state) => ({
                                            ...state,
                                            bankCode: e.target.value,
                                            bankName: bankList[e.target.selectedIndex].bankName,
                                        }))
                                    }}>
                                {bankList.map((bank, idx) => {
                                    return <option key={String(idx)} value={bank.bankCode} name={bank.bankName}>{bank.bankName}</option>
                                })}
                            </select>
                        </div>
                        <div className={"flex flex-col gap-1"}>
                            <span className={"text-white font-bold"}>예금주</span>
                            <input className={"bg-transparent text-white font-bold text-lg"} placeholder={""}
                                   onChange={e => {
                                       setCardInfo((state) => ({
                                           ...state, accountOwner: e.target.value
                                       }))
                                   }}
                                   value={cardInfo.accountOwner}/>
                        </div>
                    </div>
                    }
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-white font-bold"}>이체 포인트</span>
                        <input className={"bg-transparent text-white font-bold text-lg"} placeholder={""}
                               type={"number"}
                               onChange={e => {
                                   setCardInfo((state) => ({
                                       ...state, amount: Number(e.target.value)
                                   }))
                               }}
                               value={cardInfo.amount}/>
                    </div>
                </div>
                <button className={"flex flex-col p-4 gap-2 rounded-lg bg-gradient-to-r from-[#717171] to-[#D9C7C6] items-center text-3xl text-white font-bold"}
                        onClick={request}>
                    이 체 요 청
                </button>
                <div className={"h-[80px]"} />
            </div>
            <Alert open={isAlert} title={"Alert!"}
                   content={"요청이 관리자에게 접수되었습니다.\n관리자 승인까지는 시간이 소요될 수 있습니다."}
                   onClose={() => {
                       setIsAlert(false);
                   }} />
        </Frame>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(CardTransfer)