import icon_w3p_logo from "../assets/svg/logo.svg";
import icon_lpoint from "../assets/icon_lpoint.png";
import icon_disabled from "../assets/icon_disabled.png";

export const getBackgroundColor = (type) => {
    switch (type) {
        case "w3p":
            return "bg-[#4daa60]"
        case "lPoint":
            return "bg-[#009bfa]"
        case "disabledPoint":
            return "bg-[#878787]"
        case "happyPoint":
            return "bg-[#0d0f85]"
    }
}

export const getPayList = () => {
    return [
        {payType: "w3p", title: "W3P", logo: icon_w3p_logo, color: "#4daa60"},
        {payType: "lPoint", title: "L.Point", logo: icon_lpoint, color: "#009bfa"},
        {payType: "disabledPoint", title: "장애인후원포인트", logo: icon_disabled, color: "#878787"}
    ]
}

export const removeComma = (value) => {
    return value.replaceAll(",", "")
}

export const regexpCardNumber = (value) => {
    const cleaned = value.replace(/\D/g, '');
    return cleaned.replace(/(\d{4})(?=\d)/g, '$1-');
}

export const copyClipboard = async (text, successAction, failAction) => {
    try {
        await navigator.clipboard.writeText(text);
        successAction && successAction();
    } catch (error) {
        failAction && failAction();
    }
}