import logo from '../assets/svg/logo.svg'
import logo2 from '../assets/svg/logo2.svg'
import mklogo from '../assets/svg/mk_logo2.png'

// ### web3pays
// export const cmpId = 5
// export const message1 = "Web 3.0 기반 통합 포인트"
// export const message2 = "장애인 후원 포인트 주관사"
// export const imgLogo = (
//     <div className={"flex flex-col gap-4 items-center"}>
//         <img className={"w-32"} src={logo} alt={""}/>
//         <img src={logo2} alt={""}/>
//     </div>
// )
// export const foreColor1 = "#FF8B57"
// export const foreColor2 = "#E94738"
// export const mchtId = "ais001"
// export const apiKey = "pk_dd7c-207f77-9b8-68b93"

// ### MK
export const cmpId = 2;
export const message1 = "회원가입 승인후 부여된 카드번호를\n통해 로그인 할수 있습니다."
export const message2 = "\n법인체크카드는 등록한 사용자만 사용할 수 있으며,\n대여하거나 양도 할 수 없습니다\n" + "부정사용에 있어 책임은 사용자에게 있습니다."
export const imgLogo = <img src={mklogo} alt={""}/>
export const foreColor1 = "#333333"
export const foreColor2 = "#999999"
export const mchtId = "mkshop"
export const apiKey = "pk_f5b9-de2de5-dda-9cbcc"