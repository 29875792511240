import {useState} from "react";
import {connect, useSelector} from "react-redux";
import Frame from "../main/Frame";
import {Loading} from "../../common/Loading";
import icon_noti from "../../assets/svg/icon_noti.svg";
import numeral from "numeral";
import * as CommonUtil from "../../common/CommonUtil";
import * as api from "../../api/api"
import * as hanaApi from "../../api/hanaApi"
import {useNavigate} from "react-router-dom";

const CardRegister = (props) => {
    const {user} = useSelector((props) => props.account);

    const [loading, setLoading] = useState(false)
    const [cardInfo, setCardInfo] = useState({
        cardDiv: "Hana", cardNo: "", phone: "", password: "", pin: ""
    })
    const navigate = useNavigate();

    const register = () => {
        // validation 체크
        if (cardInfo.cardNo.length === 0 ||
            cardInfo.phone.length === 0 ||
            cardInfo.password.length === 0 ||
            cardInfo.pin.length === 0) {
            window.alert("정보를 정확하게 입력해주세요.")
            return
        }

        if (cardInfo.cardNo.length !== 16) {
            window.alert("카드번호는 16자리 입력이 필수입니다")
            return
        }

        if (window.confirm("카드를 등록하시겠어요?")) {
            setLoading(true)
            const card = {
                card_num: cardInfo.cardNo,
                tel_no: cardInfo.phone,
                password: cardInfo.password,
                pin_num: cardInfo.pin,
                is_approve: ""
            }

            hanaApi.HCRegister(user.company, card).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    if (data.result === 'OK' || data.result === 'Duplication') {
                        updateMember(card)
                    }
                    else {
                        window.alert("HanaCard Exception :: " + data.result)
                    }
                }
                else {
                    window.alert(status + "\n" + statusText)
                }
            })
                .catch(ex => {
                    console.error(ex)
                    window.alert(ex.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const updateMember = (card) => {
        const body = {
            card_num: card.card_num,
            tel_no: card.tel_no,
            password: card.password,
            pin_num: card.pin_num,
            is_approve: 'Y'
        }

        hanaApi.HCUpdateMember(user.company, body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                putUserCard()
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    const putUserCard = () => {
        setLoading(true)

        api.putUserCard(cardInfo).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                window.alert("등록이 완료되었습니다.\n다시 로그인 해 주세요.")

                navigate("/")
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Frame>
            {loading ? <Loading /> : null}
            <div className={"flex flex-col px-4 gap-2"}>
                <div className={"flex flex-row items-center justify-between"}>
                    <div className={"flex flex-row gap-2 items-center"}>
                        <img src={icon_noti} alt={""}/>
                        <span className={"text-[#454545]"}>법인체크카드 등록페이지</span>
                    </div>
                    <span className={"text-[#454545] font-bold"}>{">"}</span>
                </div>
                <div className={"h-[30px]"}/>
                <div className={"w-[360px] h-[214px] bg-[url('./assets/img_card.png')] flex flex-col py-5"}>
                    <div className={"h-[92px]"} />
                    <div className={"flex flex-col items-center justify-center"}>
                        <span className={"text-white text-2xl font-bold"}>
                            {CommonUtil.regexpCardNumber(cardInfo.cardNo)}
                        </span>
                    </div>
                </div>
                <div className={"flex flex-col bg-[#878787] rounded-lg shadow-md p-5 gap-4"}>
                    <input className={"bg-transparent text-white font-bold text-lg"} placeholder={"카드번호"}
                           maxLength={16}
                           type={"number"}
                           onChange={e => {
                               setCardInfo((state) => ({
                                   ...state, cardNo: e.target.value
                               }))
                           }}
                           value={cardInfo.cardNo}/>
                    <input className={"bg-transparent text-white font-bold text-lg"} placeholder={"전화번호"}
                           maxLength={12}
                           type={"tel"}
                           onChange={e => {
                               setCardInfo((state) => ({
                                   ...state, phone: e.target.value
                               }))
                           }}
                           value={cardInfo.phone}/>
                    <input className={"bg-transparent text-white font-bold text-lg"} placeholder={"비밀번호"}
                           type={"password"}
                           onChange={e => {
                               setCardInfo((state) => ({
                                   ...state, password: e.target.value
                               }))
                           }}
                           value={cardInfo.password}/>
                    <input className={"bg-transparent text-white font-bold text-lg"} placeholder={"PIN번호"}
                           maxLength={6}
                           type={"password"}
                           onChange={e => {
                               setCardInfo((state) => ({
                                   ...state, pin: e.target.value
                               }))
                           }}
                           value={cardInfo.pin}/>
                </div>
                <button className={"flex flex-col p-4 gap-2 rounded-lg bg-gradient-to-r from-[#717171] to-[#D9C7C6] items-center text-3xl text-white font-bold"}
                        onClick={register}>
                    카 드 등 록
                </button>

                <div className={"h-[80px]"} />
            </div>
        </Frame>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(CardRegister)